import { useState, useEffect } from "react";
import axios from "axios";
import ImgDialog from "./ImgDialog";
import {
  Box,
  Stack,
  TextField,
  Pagination,
  ImageList,
  ImageListItem,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import CircularProgress from "@mui/material/CircularProgress";

// just fix build

const loadServerRows = ({
  page,
  pageSize,
  sortModel,
  presta,
  term,
  dossier,
}) => {
  const params = {
    limit: pageSize,
    page: page - 1,
    presta: presta,
    term: term,
    dossier: dossier,
  };
  if (sortModel?.[0]) {
    params.sort = sortModel[0].field;
    params.order = sortModel[0].sort;
  }
  return axios.get(process.env.REACT_APP_GPRC_BASE_URL + "/arka/livraisons", {
    params,
  });
};

const useQuery = rowState => {
  const [rowCount, setRowCount] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    let active = true;

    loadServerRows(rowState).then(({ data: { count, rows } }) => {
      if (!active) {
        return;
      }
      setData(rows);
      setRowCount(count);
    });
    return () => {
      active = false;
    };
  }, [rowState]);

  return { data, rowCount };
};

const typePrestation = () => {
  return axios.get(
    process.env.REACT_APP_GPRC_BASE_URL + "/arka/typePrestation"
  );
};

export default function Prestations() {
  const [rowsState, setRowsState] = useState({
    page: 1,
    pageSize: 25,
    sortModel: [
      {
        field: "projet_id",
        sort: "desc",
      },
    ],
  });

  const { data, rowCount } = useQuery(rowsState);
  const [typePresta, setTypePresta] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [itemSelected, setItem] = useState(null);
  const [term, setTerm] = useState("");
  const [dossier, setDossier] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [presta, setPresta] = useState("");
  const [loading, setLoading] = useState(false);
  const modalAction = (open, item) => {
    setItem(item);
    setOpenModal(open);
  };
  const downloadAction = item => {
    fetch(item.fullUrl, {
      method: "GET",
      headers: {},
    }).then(response => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        const splitUrl = item.fullUrl.split(".");
        const ext = splitUrl[splitUrl.length - 1];
        const name = item.Prestation.id + "." + ext;
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
    });
  };

  useEffect(() => {
    typePrestation().then(response => {
      setTypePresta(response.data);
    });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setRowsState(prev => ({
        ...prev,
        page: 1,
        presta,
        term,
        dossier,
      }));
      setcurrentPage(1);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [presta, term, dossier]);

  const termKeyUp = e => {
    setTerm(e.target.value);
  };

  const dossierKeyUp = e => {
    setDossier(e.target.value);
  };

  const prestaChange = e => {
    setPresta(e.target.value);
  };

  return (
    <div>
      <Stack
        spacing={2}
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box sx={{ display: "flex", flex: 1 }}>
          <Box sx={{ display: "flex", flex: "35%", marginRight: "5%" }}>
            <FormControl fullWidth>
              <InputLabel
                id="search-by-term"
                shrink="true"
                sx={{ backgroundColor: "white", padding: "0 5px 0 5px" }}
              >
                Societe / ville / nom de projet
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                onKeyUp={termKeyUp}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flex: "20%", marginRight: "5%" }}>
            <FormControl fullWidth>
              <InputLabel
                id="search-dossier"
                shrink="true"
                sx={{ backgroundColor: "white", padding: "0 5px 0 5px" }}
              >
                Dossier
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                onKeyUp={dossierKeyUp}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flex: "30%" }}>
            <FormControl fullWidth>
              <InputLabel
                id="search-prestation"
                shrink="true"
                sx={{ backgroundColor: "white", padding: "0 5px 0 5px" }}
              >
                Prestation
              </InputLabel>
              <Select
                labelId="type-presta"
                value={presta}
                id="type-presta-select"
                label="Prestation"
                onChange={prestaChange}
                size="small"
                displayEmpty
              >
                <MenuItem key="allPresta" value="">
                  Tout type de prestation
                </MenuItem>
                {typePresta.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flex: "10%", justifyContent: "center" }}>
            {loading && <CircularProgress color="inherit" />}
          </Box>
        </Box>
        <Box>
          <Pagination
            page={currentPage}
            count={Math.ceil(rowCount / 25)}
            shape="rounded"
            onChange={(event, page) => {
              setcurrentPage(page);
              setRowsState(prev => ({ ...prev, page }));
            }}
          />
        </Box>
      </Stack>
      <ImageList variant="masonry" cols={4} gap={8}>
        {data.map(item => (
          <ImageListItem
            key={item.id}
            sx={{ minWidth: "100%", minHeight: "40px", cursor: "pointer" }}
          >
            {!item.url && (
              <img
                src={`${item.imageUrl}?w=248&fit=crop&auto=format`}
                srcSet={`${item.imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.prestation_id}
                loading="lazy"
                onClick={() => modalAction(true, item)}
              />
            )}
            {item.url && (
              <img
                src={`https://s3.eu-west-3.amazonaws.com/static.aws.immodesk.fr/svgs/visite/360-degrees.png?w=248&fit=crop&auto=format`}
                srcSet={`${item.imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.prestation_id}
                loading="lazy"
                onClick={() => modalAction(true, item)}
              />
            )}
            <Box
              sx={{
                backgroundColor: "rgba(0,0,0,0.7)",
                position: "absolute",
                display: "flex",
                height: "50px",
                padding: "5px",
                bottom: 0,
                color: "white",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "default",
              }}
            >
              {item.Prestation && (
                <Box
                  sx={{
                    fontSize: "0.8rem",
                    lineHeight: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>{item.Prestation.Projet.Societe.nom}</Box>
                  <Box>
                    {item.Prestation.Projet.ref}-
                    {item.Prestation.Projet.exercice}{" "}
                    {item.Prestation.Projet.nom}
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: "5px",
                }}
              >
                <Box>
                  {!item.url && (
                    <FileDownloadOutlinedIcon
                      sx={{ fontSize: "1rem", cursor: "pointer" }}
                      onClick={() => downloadAction(item)}
                    />
                  )}
                </Box>
                <Box>
                  <FullscreenOutlinedIcon
                    sx={{ fontSize: "1rem", cursor: "pointer" }}
                    onClick={() => modalAction(true, item)}
                  />
                </Box>
              </Box>
            </Box>
          </ImageListItem>
        ))}
      </ImageList>
      <ImgDialog
        open={openModal}
        onClose={() => modalAction(false, null)}
        prog={itemSelected}
      />
    </div>
  );
}
